html body .app.flex-row.align-items-center {
  height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
.sidebar .nav{
  width: 250px;
}
.btn-top{
  float: left;
  margin-top: 20px;
  margin-bottom: 15px;

}
.img-size{
  width:50px;
  height:50px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  object-fit: cover;
  max-width: 50px;
}
.img-session{
  width:70px;
  height:70px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle 
}
.img-edit{
  width:150px;
  height:150px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  object-fit: cover !important;
}
.img-docs{
  width:150px;
  height:150px;
  // border-radius: 50%;
  // overflow: hidden;
  vertical-align: middle;
  object-fit: contain;
}
.img-doc-modal{
  width:400px;
  height:400px;
  // border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  object-fit: contain;
}
.td{
  width:20%
}
.pic-col{
  flex-grow: 0;
}
.row-class{
  padding: 25px;
}
.label-class{
  font-weight: 700;
}
