// Here you can add other styles

#btn-red .rrt-message {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#btn-red .rrt-ok-btn {
  color: #fff;
  background-color: #3a9d5d;
  border-color: #3a9d5d;
  line-height: 32px;
  height: 32px;
  border-radius: 20px;
  max-width: 100px;
}
#btn-red .rrt-cancel-btn {
  color: #fff;
  background-color: #f63c3a;
  border-color: #f5302e;
  line-height: 32px;
  height: 32px;
  border-radius: 20px;
  max-width: 100px;
}
#btn-red .rrt-buttons-holder {
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: space-around;
  border-top: 1px solid #cccccc;
}

.hidden {
  display: none;
}
